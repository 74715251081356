import React from 'react';

const NavUxUi = ({ className }: { className?: string }) => (
  <svg
    className={className}
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.1992 28.4267H7.58017C7.47914 28.4267 7.38218 28.3865 7.31073 28.3151C7.23929 28.2437 7.19922 28.1468 7.19922 28.0458V4.38095C7.19922 4.27992 7.23929 4.18305 7.31073 4.11161C7.38218 4.04016 7.47914 4 7.58017 4H15.1992C15.3003 4 15.3971 4.04016 15.4686 4.11161C15.54 4.18305 15.5802 4.27992 15.5802 4.38095V28.0458C15.5802 28.1468 15.54 28.2437 15.4686 28.3151C15.3971 28.3865 15.3003 28.4267 15.1992 28.4267ZM8.00679 27.6496H14.8183V4.79241H8.00679V27.6496Z"
      fill="#1A1A1A"
    />
    <mask id="path-2-inside-1_3560_251827" fill="white">
      <path d="M15.1998 4.77716H12.0455C11.9556 4.75927 11.8746 4.71073 11.8165 4.63988C11.7584 4.56903 11.7266 4.48023 11.7266 4.38858C11.7266 4.29692 11.7584 4.20813 11.8165 4.13728C11.8746 4.06642 11.9556 4.01789 12.0455 4H15.1998C15.2897 4.01789 15.3705 4.06642 15.4287 4.13728C15.4868 4.20813 15.5186 4.29692 15.5186 4.38858C15.5186 4.48023 15.4868 4.56903 15.4287 4.63988C15.3705 4.71073 15.2897 4.75927 15.1998 4.77716V4.77716Z" />
    </mask>
    <path
      d="M15.1998 4.77716H12.0455C11.9556 4.75927 11.8746 4.71073 11.8165 4.63988C11.7584 4.56903 11.7266 4.48023 11.7266 4.38858C11.7266 4.29692 11.7584 4.20813 11.8165 4.13728C11.8746 4.06642 11.9556 4.01789 12.0455 4H15.1998C15.2897 4.01789 15.3705 4.06642 15.4287 4.13728C15.4868 4.20813 15.5186 4.29692 15.5186 4.38858C15.5186 4.48023 15.4868 4.56903 15.4287 4.63988C15.3705 4.71073 15.2897 4.75927 15.1998 4.77716V4.77716Z"
      fill="#1A1A1A"
    />
    <path
      d="M12.0455 4.77716L11.8503 5.75793L11.9469 5.77716H12.0455V4.77716ZM12.0455 4V3H11.9469L11.8503 3.01923L12.0455 4ZM15.1998 4L15.3949 3.01923L15.2983 3H15.1998V4ZM15.1998 3.77716H12.0455V5.77716H15.1998V3.77716ZM12.2407 3.79639C12.3777 3.82367 12.501 3.8976 12.5895 4.00552L11.0435 5.27424C11.2483 5.52387 11.5334 5.69487 11.8503 5.75793L12.2407 3.79639ZM12.5895 4.00552C12.678 4.11336 12.7266 4.24871 12.7266 4.38858H10.7266C10.7266 4.71176 10.8387 5.0247 11.0435 5.27424L12.5895 4.00552ZM12.7266 4.38858C12.7266 4.52845 12.678 4.6638 12.5895 4.77164L11.0435 3.50292C10.8387 3.75246 10.7266 4.0654 10.7266 4.38858H12.7266ZM12.5895 4.77164C12.501 4.87956 12.3777 4.95349 12.2407 4.98077L11.8503 3.01923C11.5334 3.08229 11.2483 3.25329 11.0435 3.50292L12.5895 4.77164ZM12.0455 5H15.1998V3H12.0455V5ZM15.0046 4.98077C14.8672 4.95342 14.744 4.87927 14.6556 4.77164L16.2017 3.50292C15.9971 3.25358 15.7122 3.08236 15.3949 3.01923L15.0046 4.98077ZM14.6556 4.77164C14.5671 4.6638 14.5186 4.52845 14.5186 4.38858H16.5186C16.5186 4.0654 16.4065 3.75246 16.2017 3.50292L14.6556 4.77164ZM14.5186 4.38858C14.5186 4.24871 14.5671 4.11336 14.6556 4.00552L16.2017 5.27424C16.4065 5.0247 16.5186 4.71176 16.5186 4.38858H14.5186ZM14.6556 4.00552C14.744 3.89789 14.8672 3.82374 15.0046 3.79639L15.3949 5.75793C15.7122 5.6948 15.9971 5.52358 16.2017 5.27424L14.6556 4.00552Z"
      fill="#344054"
      mask="url(#path-2-inside-1_3560_251827)"
    />
    <mask id="path-4-inside-2_3560_251827" fill="white">
      <path d="M15.1998 7.73663H12.0455C11.9556 7.71874 11.8746 7.67021 11.8165 7.59935C11.7584 7.5285 11.7266 7.43971 11.7266 7.34805C11.7266 7.2564 11.7584 7.1676 11.8165 7.09675C11.8746 7.0259 11.9556 6.97736 12.0455 6.95947H15.1998C15.2897 6.97736 15.3705 7.0259 15.4287 7.09675C15.4868 7.1676 15.5186 7.2564 15.5186 7.34805C15.5186 7.43971 15.4868 7.5285 15.4287 7.59935C15.3705 7.67021 15.2897 7.71874 15.1998 7.73663V7.73663Z" />
    </mask>
    <path
      d="M15.1998 7.73663H12.0455C11.9556 7.71874 11.8746 7.67021 11.8165 7.59935C11.7584 7.5285 11.7266 7.43971 11.7266 7.34805C11.7266 7.2564 11.7584 7.1676 11.8165 7.09675C11.8746 7.0259 11.9556 6.97736 12.0455 6.95947H15.1998C15.2897 6.97736 15.3705 7.0259 15.4287 7.09675C15.4868 7.1676 15.5186 7.2564 15.5186 7.34805C15.5186 7.43971 15.4868 7.5285 15.4287 7.59935C15.3705 7.67021 15.2897 7.71874 15.1998 7.73663V7.73663Z"
      fill="#1A1A1A"
    />
    <path
      d="M12.0455 7.73663L11.8503 8.7174L11.9469 8.73663H12.0455V7.73663ZM11.7266 7.34805L10.7266 7.34805L11.7266 7.34805ZM12.0455 6.95947V5.95947H11.9469L11.8503 5.9787L12.0455 6.95947ZM15.1998 6.95947L15.3949 5.9787L15.2983 5.95947H15.1998V6.95947ZM15.1998 6.73663H12.0455V8.73663H15.1998V6.73663ZM12.2407 6.75586C12.3777 6.78314 12.501 6.85707 12.5895 6.96499L11.0435 8.23372C11.2483 8.48334 11.5334 8.65434 11.8503 8.7174L12.2407 6.75586ZM12.5895 6.96499C12.678 7.07283 12.7266 7.20818 12.7266 7.34805L10.7266 7.34805C10.7266 7.67123 10.8387 7.98417 11.0435 8.23372L12.5895 6.96499ZM12.7266 7.34805C12.7266 7.48792 12.678 7.62327 12.5895 7.73111L11.0435 6.46239C10.8387 6.71193 10.7266 7.02487 10.7266 7.34805L12.7266 7.34805ZM12.5895 7.73111C12.501 7.83903 12.3777 7.91296 12.2407 7.94024L11.8503 5.9787C11.5334 6.04176 11.2483 6.21276 11.0435 6.46239L12.5895 7.73111ZM12.0455 7.95947H15.1998V5.95947H12.0455V7.95947ZM15.0046 7.94024C14.8672 7.91289 14.7439 7.83874 14.6556 7.73111L16.2017 6.46239C15.9971 6.21305 15.7122 6.04183 15.3949 5.9787L15.0046 7.94024ZM14.6556 7.73111C14.5671 7.62327 14.5186 7.48792 14.5186 7.34805H16.5186C16.5186 7.02487 16.4065 6.71193 16.2017 6.46239L14.6556 7.73111ZM14.5186 7.34805C14.5186 7.20818 14.5671 7.07283 14.6556 6.96499L16.2017 8.23372C16.4065 7.98417 16.5186 7.67123 16.5186 7.34805H14.5186ZM14.6556 6.96499C14.7439 6.85736 14.8672 6.78321 15.0046 6.75586L15.3949 8.7174C15.7122 8.65427 15.9971 8.48305 16.2017 8.23372L14.6556 6.96499Z"
      fill="#344054"
      mask="url(#path-4-inside-2_3560_251827)"
    />
    <mask id="path-6-inside-3_3560_251827" fill="white">
      <path d="M15.1998 10.6863H12.0455C11.9556 10.6684 11.8746 10.6199 11.8165 10.5491C11.7584 10.4782 11.7266 10.3894 11.7266 10.2978C11.7266 10.2061 11.7584 10.1173 11.8165 10.0465C11.8746 9.9756 11.9556 9.92707 12.0455 9.90918H15.1998C15.2897 9.92707 15.3705 9.9756 15.4287 10.0465C15.4868 10.1173 15.5186 10.2061 15.5186 10.2978C15.5186 10.3894 15.4868 10.4782 15.4287 10.5491C15.3705 10.6199 15.2897 10.6684 15.1998 10.6863V10.6863Z" />
    </mask>
    <path
      d="M15.1998 10.6863H12.0455C11.9556 10.6684 11.8746 10.6199 11.8165 10.5491C11.7584 10.4782 11.7266 10.3894 11.7266 10.2978C11.7266 10.2061 11.7584 10.1173 11.8165 10.0465C11.8746 9.9756 11.9556 9.92707 12.0455 9.90918H15.1998C15.2897 9.92707 15.3705 9.9756 15.4287 10.0465C15.4868 10.1173 15.5186 10.2061 15.5186 10.2978C15.5186 10.3894 15.4868 10.4782 15.4287 10.5491C15.3705 10.6199 15.2897 10.6684 15.1998 10.6863V10.6863Z"
      fill="#1A1A1A"
    />
    <path
      d="M12.0455 10.6863L11.8503 11.6671L11.9469 11.6863H12.0455V10.6863ZM11.7266 10.2978L10.7266 10.2978L11.7266 10.2978ZM12.0455 9.90918V8.90918H11.9469L11.8503 8.92841L12.0455 9.90918ZM15.1998 9.90918L15.3949 8.92841L15.2983 8.90918H15.1998V9.90918ZM15.1998 9.68634H12.0455V11.6863H15.1998V9.68634ZM12.2407 9.70557C12.3777 9.73285 12.501 9.80678 12.5895 9.9147L11.0435 11.1834C11.2483 11.433 11.5334 11.6041 11.8503 11.6671L12.2407 9.70557ZM12.5895 9.9147C12.678 10.0225 12.7266 10.1579 12.7266 10.2978L10.7266 10.2978C10.7266 10.6209 10.8387 10.9339 11.0435 11.1834L12.5895 9.9147ZM12.7266 10.2978C12.7266 10.4376 12.678 10.573 12.5895 10.6808L11.0435 9.4121C10.8387 9.66164 10.7266 9.97458 10.7266 10.2978L12.7266 10.2978ZM12.5895 10.6808C12.501 10.7887 12.3777 10.8627 12.2407 10.8899L11.8503 8.92841C11.5334 8.99147 11.2483 9.16247 11.0435 9.4121L12.5895 10.6808ZM12.0455 10.9092H15.1998V8.90918H12.0455V10.9092ZM15.0046 10.8899C14.8672 10.8626 14.7439 10.7884 14.6556 10.6808L16.2017 9.4121C15.9971 9.16276 15.7122 8.99154 15.3949 8.92841L15.0046 10.8899ZM14.6556 10.6808C14.5671 10.573 14.5186 10.4376 14.5186 10.2978H16.5186C16.5186 9.97458 16.4065 9.66164 16.2017 9.4121L14.6556 10.6808ZM14.5186 10.2978C14.5186 10.1579 14.5671 10.0225 14.6556 9.9147L16.2017 11.1834C16.4065 10.9339 16.5186 10.6209 16.5186 10.2978H14.5186ZM14.6556 9.9147C14.7439 9.80707 14.8672 9.73292 15.0046 9.70557L15.3949 11.6671C15.7122 11.604 15.9971 11.4328 16.2017 11.1834L14.6556 9.9147Z"
      fill="#344054"
      mask="url(#path-6-inside-3_3560_251827)"
    />
    <path
      d="M15.1998 13.6458H12.0455C11.9556 13.6279 11.8746 13.5794 11.8165 13.5085C11.7584 13.4377 11.7266 13.3489 11.7266 13.2572C11.7266 13.1656 11.7584 13.0768 11.8165 13.0059C11.8746 12.9351 11.9556 12.8865 12.0455 12.8687H15.1998C15.2897 12.8865 15.3705 12.9351 15.4287 13.0059C15.4868 13.0768 15.5186 13.1656 15.5186 13.2572C15.5186 13.3489 15.4868 13.4377 15.4287 13.5085C15.3705 13.5794 15.2897 13.6279 15.1998 13.6458V13.6458Z"
      fill="#1A1A1A"
    />
    <path
      d="M15.1998 16.6053H12.0455C11.9556 16.5874 11.8746 16.5389 11.8165 16.468C11.7584 16.3972 11.7266 16.3084 11.7266 16.2167C11.7266 16.125 11.7584 16.0363 11.8165 15.9654C11.8746 15.8945 11.9556 15.846 12.0455 15.8281H15.1998C15.2897 15.846 15.3705 15.8945 15.4287 15.9654C15.4868 16.0363 15.5186 16.125 15.5186 16.2167C15.5186 16.3084 15.4868 16.3972 15.4287 16.468C15.3705 16.5389 15.2897 16.5874 15.1998 16.6053V16.6053Z"
      fill="#1A1A1A"
    />
    <path
      d="M15.1998 19.5584H12.0455C11.9556 19.5405 11.8746 19.492 11.8165 19.4211C11.7584 19.3503 11.7266 19.2615 11.7266 19.1698C11.7266 19.0782 11.7584 18.9894 11.8165 18.9185C11.8746 18.8477 11.9556 18.7991 12.0455 18.7812H15.1998C15.2897 18.7991 15.3705 18.8477 15.4287 18.9185C15.4868 18.9894 15.5186 19.0782 15.5186 19.1698C15.5186 19.2615 15.4868 19.3503 15.4287 19.4211C15.3705 19.492 15.2897 19.5405 15.1998 19.5584V19.5584Z"
      fill="#1A1A1A"
    />
    <path
      d="M15.1993 22.5145H12.045C11.944 22.5145 11.8471 22.4743 11.7757 22.4029C11.7042 22.3314 11.6641 22.2345 11.6641 22.1335C11.664 22.031 11.7036 21.9325 11.7746 21.8586C11.8457 21.7847 11.9426 21.7412 12.045 21.7373H15.1993C15.3017 21.7412 15.3986 21.7847 15.4697 21.8586C15.5407 21.9325 15.5803 22.031 15.5803 22.1335C15.5803 22.2345 15.5401 22.3314 15.4687 22.4029C15.3972 22.4743 15.3003 22.5145 15.1993 22.5145Z"
      fill="#1A1A1A"
    />
    <path
      d="M15.1993 25.4739H12.045C11.944 25.4739 11.8471 25.4338 11.7757 25.3623C11.7042 25.2909 11.6641 25.194 11.6641 25.093C11.664 24.9905 11.7036 24.8919 11.7746 24.8181C11.8457 24.7442 11.9426 24.7007 12.045 24.6968H15.1993C15.3017 24.7007 15.3986 24.7442 15.4697 24.8181C15.5407 24.8919 15.5803 24.9905 15.5803 25.093C15.5803 25.194 15.5401 25.2909 15.4687 25.3623C15.3972 25.4338 15.3003 25.4739 15.1993 25.4739Z"
      fill="#1A1A1A"
    />
    <path
      d="M20.9738 8.37668C20.9035 8.39194 20.8307 8.39194 20.7604 8.37668L19.6024 7.59952C19.5575 7.5739 19.5182 7.53944 19.4871 7.49815C19.4559 7.45686 19.4334 7.40962 19.4211 7.35938C19.4088 7.30915 19.4068 7.25694 19.4154 7.20592C19.4239 7.1549 19.4427 7.1062 19.4707 7.06269C19.4987 7.01918 19.5351 6.98188 19.578 6.95295C19.6209 6.92402 19.6692 6.904 19.72 6.89435C19.7709 6.88471 19.8231 6.88557 19.8736 6.89677C19.9241 6.90798 19.9718 6.92941 20.0138 6.95964L20.9738 7.58427L21.9185 6.95964C21.9834 6.9222 22.057 6.90235 22.1319 6.90235C22.2068 6.90235 22.2804 6.9222 22.3452 6.95964L23.3052 7.58427L24.25 6.95964C24.3349 6.90306 24.4387 6.88253 24.5387 6.90254C24.6387 6.92254 24.7267 6.98136 24.7833 7.06623C24.8399 7.1511 24.8604 7.25509 24.8404 7.35511C24.8204 7.45512 24.7615 7.54295 24.6766 7.59952L23.5033 8.37668C23.4413 8.41435 23.3702 8.43435 23.2976 8.43435C23.2251 8.43435 23.1539 8.41435 23.0919 8.37668L22.1319 7.7368L21.1871 8.37668C21.1167 8.39026 21.0442 8.39026 20.9738 8.37668V8.37668Z"
      fill="#1A1A1A"
    />
    <path
      d="M24.5087 24.5261H19.861C19.7586 24.5261 19.66 24.4864 19.5861 24.4154C19.5122 24.3444 19.4688 24.2475 19.4648 24.1451V7.21543C19.4827 7.12553 19.5313 7.04477 19.6021 6.98663C19.673 6.92849 19.7618 6.8966 19.8534 6.8966C19.9451 6.8966 20.0339 6.92849 20.1047 6.98663C20.1756 7.04477 20.2241 7.12553 20.242 7.21543V23.7642H24.1277V7.21543C24.1277 7.11439 24.1679 7.01752 24.2393 6.94608C24.3107 6.87464 24.4076 6.83447 24.5087 6.83447C24.6097 6.83447 24.7066 6.87464 24.778 6.94608C24.8494 7.01752 24.8896 7.11439 24.8896 7.21543V24.1451C24.8896 24.2461 24.8494 24.343 24.778 24.4145C24.7066 24.4859 24.6097 24.5261 24.5087 24.5261Z"
      fill="#1A1A1A"
    />
    <path
      d="M24.5087 28.4255H19.861C19.7586 28.4255 19.66 28.3858 19.5861 28.3148C19.5122 28.2437 19.4688 28.1469 19.4648 28.0445V24.1436C19.4688 24.0412 19.5122 23.9442 19.5861 23.8732C19.66 23.8022 19.7586 23.7626 19.861 23.7627H24.5087C24.6097 23.7627 24.7066 23.8027 24.778 23.8741C24.8494 23.9456 24.8896 24.0426 24.8896 24.1436V28.0445C24.8896 28.1455 24.8494 28.2424 24.778 28.3138C24.7066 28.3853 24.6097 28.4255 24.5087 28.4255ZM20.242 27.6483H24.1277V24.6007H20.242V27.6483Z"
      fill="#1A1A1A"
    />
    <path
      d="M24.5103 7.59845C24.455 7.59798 24.4004 7.58549 24.3504 7.5618C24.3004 7.53812 24.2562 7.50387 24.2208 7.46136L22.1788 4.99279L20.1522 7.46136C20.1202 7.50038 20.0808 7.5328 20.0363 7.55659C19.9918 7.58039 19.943 7.59517 19.8928 7.60012C19.8425 7.60507 19.7919 7.60007 19.7436 7.58543C19.6953 7.57078 19.6502 7.54676 19.6112 7.51474C19.5722 7.48272 19.5399 7.44337 19.5161 7.39886C19.4923 7.35434 19.4775 7.30549 19.4726 7.25525C19.4676 7.20502 19.4726 7.15438 19.4873 7.10607C19.5019 7.05777 19.5259 7.01284 19.5579 6.97382L21.8894 4.13956C21.9278 4.09945 21.9739 4.06742 22.0249 4.04562C22.076 4.02382 22.1309 4.0127 22.1865 4.0127C22.242 4.0127 22.297 4.02382 22.348 4.04562C22.3991 4.06742 22.4452 4.09945 22.4836 4.13956L24.7998 6.97382C24.8367 7.00936 24.866 7.05188 24.8861 7.099C24.9061 7.14613 24.9164 7.19679 24.9164 7.248C24.9164 7.29921 24.9061 7.34987 24.8861 7.397C24.866 7.44412 24.8367 7.48683 24.7998 7.52237C24.7146 7.57954 24.6126 7.60627 24.5103 7.59845V7.59845Z"
      fill="#1A1A1A"
    />
    <path
      d="M22.1638 22.5128C22.0613 22.5129 21.9627 22.4731 21.8889 22.4021C21.815 22.3311 21.7715 22.2342 21.7676 22.1318V9.74326C21.7715 9.64084 21.815 9.54402 21.8889 9.47298C21.9627 9.40195 22.0613 9.36223 22.1638 9.3623C22.2648 9.3623 22.3617 9.40247 22.4331 9.47391C22.5046 9.54535 22.5447 9.64222 22.5447 9.74326V22.1318C22.5447 22.2329 22.5046 22.3297 22.4331 22.4012C22.3617 22.4726 22.2648 22.5128 22.1638 22.5128Z"
      fill="#1A1A1A"
    />
    <path
      d="M24.509 26.0183H19.8614C19.7604 26.0183 19.6634 25.9781 19.592 25.9066C19.5205 25.8352 19.4805 25.7383 19.4805 25.6373C19.4805 25.5363 19.5205 25.4394 19.592 25.368C19.6634 25.2965 19.7604 25.2563 19.8614 25.2563H24.509C24.6101 25.2563 24.7069 25.2965 24.7784 25.368C24.8498 25.4394 24.89 25.5363 24.89 25.6373C24.89 25.7383 24.8498 25.8352 24.7784 25.9066C24.7069 25.9781 24.6101 26.0183 24.509 26.0183Z"
      fill="#1A1A1A"
    />
  </svg>
);

export default NavUxUi;
